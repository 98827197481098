import { IPaginationData } from '@/interfaces/common';
import { ITransaction } from '@/interfaces/entities';
import { IGetTransactionsParams } from '@/interfaces/models/transaction';
import { BaseAPI } from './base.api';

class TransactionAPI extends BaseAPI {
  controller = '/transaction';
  get(params: IGetTransactionsParams = { page: 0, size: 20 }) {
    return this._get<IPaginationData<ITransaction>>('/', { params });
  }
}
const api = new TransactionAPI();
export default api;
