import DocumentAPI from '@/api/document.api';
import { IDocument } from '@/interfaces/entities';
import useMessage from './useMessage';
const { requestError } = useMessage();

function downloadDocument(uid: string) {
  return DocumentAPI.download(uid);
}

function uploadDocument(uid: string, file: File) {
  const form = new FormData();
  form.append('file', file);

  return DocumentAPI.signed(uid, form).catch(requestError);
}

async function executeDownloading(_file: IDocument) {
  const { uid, filename, title } = _file;
  try {
    const file = await downloadDocument(uid);
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', title || filename);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    requestError(e);
  }
}

export default () => ({
  downloadDocument,
  uploadDocument,
  executeDownloading,
});
